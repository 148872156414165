import { GUID } from "../app/common/helper/Guid";
import BaseService from "./BaseService";

export default class PackageService extends BaseService {
    controllerName = "packages";

    async GetPackageInfo(packageID: GUID, includeVersionHistory: boolean = true, versionSearch: string = ""): Promise<any> {
        const resp = versionSearch 
            ? await this.Get(`${packageID}`, { includeVersionHistory, versionSearch })
            : await this.Get(`${packageID}`, { includeVersionHistory });
        return resp ? resp.data : null;
    }

    async DownloadPackage(packageID: GUID, version: string){
        const resp = await this.GetBlob(`${packageID}/download`, { version });
        return resp.data ? resp.data : resp;
    }
    
    async GetDownloadLinkAsync (packageID: GUID, version: string){
        const resp = await this.Get(`${packageID}/link`, { version });
        return resp.data ? resp.data : resp;
    }
}
