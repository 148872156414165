import axios from "axios";
import EstablishmentService from "./EstablishmentService";
import ProductService from "./ProductService";
import ToastService from "./ToastService";
import PackageService from "./PackageService";
import SalesforceService from "./SalesforceService";
import MessageService from "./MessageService";
import UserService from "./UserService";
import RoleService from "./RoleService";
import AlertService from "./AlertService";

export class ServiceManager {
  public static EstablishmentService: EstablishmentService = new EstablishmentService();
  public static ProductService: ProductService = new ProductService();
  public static ToastServer: ToastService = new ToastService();
  public static PackageService: PackageService = new PackageService();
  public static SalesforceService: SalesforceService = new SalesforceService();
  public static MessageService: MessageService = new MessageService();
  public static UserService: UserService = new UserService();
  public static RoleService: RoleService = new RoleService();
  public static AlertService: AlertService = new AlertService();
}

let interceptorId: number | null = null;

export const setAxiosTokenInterceptor = async (accessToken: undefined | string): Promise<void> => {
  if (interceptorId !== null) {
    axios.interceptors.request.eject(interceptorId);
  }

  interceptorId = axios.interceptors.request.use(async config => {
    const requestConfig = config
    if (accessToken) {
      requestConfig.headers.Authorization = `Bearer ${accessToken}`
    } 
    return requestConfig
  })
}