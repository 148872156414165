
import BaseService from "./BaseService";
import { GUID } from "../app/common/helper/Guid";

export default class MessageService extends BaseService {
    controllerName = "messages";

    async GetAll(dateStart: string, dateFinish: string, page: number, pageSize: number, continuationToken?: string, logLevel?: number, category?: string, establishmentId?: GUID, deviceId?: GUID, productId?: GUID, packageId?: GUID, messageId?: GUID): Promise<any> {
        const resp = await this.Post("", {
            dateStart: dateStart,
            dateFinish: dateFinish,
            establishmentId: establishmentId,
            deviceId: deviceId,
            productId: productId,
            packageId: packageId,
            logLevel: logLevel,
            category: category,
            continuationToken: continuationToken === "" ? null : continuationToken,
            page: page,
            pageSize: pageSize,
            messageId: messageId
        });
        return resp ? resp.data : null;
    }

    async GetMessageStatistics(deviceId: any, startDate: any, endDate: any): Promise<any> {
        const resp = await this.Get(`statistics`, {
            DateStart: startDate,
            DateFinish: endDate,
            DeviceID: deviceId
        });

        return resp ? resp.data : null;
    }

    async GetStatistics(dateStart: string, dateFinish: string, logLevel?: number, establishmentId?: GUID, deviceId?: GUID,
        productId?: GUID, packageId?: GUID, category?: string) {
        const params: any = {
            DateStart: dateStart,
            DateFinish: dateFinish,
            LogLevel: logLevel ?? null,
            EstablishmentID: establishmentId ?? null,
            DeviceID: deviceId ?? null,
            ProductID: productId ?? null,
            PackageID: packageId ?? null,
            Category: category ?? null
        }
        Object.keys(params).forEach(key => {
            if (params[key] === null) {
                delete params[key];
            }
        });
        const resp = await this.Get(`statistics`, params);

        return resp.data ? resp.data : resp;
    }

}